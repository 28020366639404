import React, {useState, forwardRef } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import {useNavigate } from "react-router-dom";
import WarningIcon from '../../assets/image/svg/Icon_Warning.svg';
import CalenderIcon from '../../assets/image/svg/Icon_Calender_01.svg';
import BackIcon from '../../assets/image/svg/back-arrow.svg';
import SearchIcon from '../../assets/image/svg/search-icon.svg';
import { Swiper, SwiperSlide } from "swiper/react";
import DatePicker from 'react-datepicker';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";

const Header = () => {
const [startDate, setStartDate] = useState(new Date());
const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
<p className="text-white text-end" onClick={onClick} ref={ref}>
<span><img src={CalenderIcon} alt="calender" /></span>  {value}
</p>
));

const navigate = useNavigate();

  return (
    <>
        <Container fluid className='m-0 p-0 overflow-x-hidden'>
            <Row>
                <Col lg={12}>
                    <div className='home-intro-section'>
                        <Row>
                            <Col xs={12}>
                                <div className='d-flex justify-content-between mt-3'>
                                    <h4 className='select-date'><span className='mr-3' onClick={() => navigate(-1)}><img src={BackIcon} alt="back"/></span></h4>
                                    {/* <h4 className='calender-title m-top-5'><span className='mr-1'><img src={CalenderIcon} alt=""/></span> June 2022</h4> */}
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        customInput={<ExampleCustomInput />}
                                        dateFormat="MMMM yyyy"
                                    />
                                </div>
                            </Col>
                            <Col xs={12} className='mt-2'>
                                <Swiper
                                    slidesPerView={7}
                                    spaceBetween={10}
                                    pagination={{
                                    clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">MO</p>
                                            <p className="current-date">12</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">TU</p>
                                            <p className="current-date">13</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list active-list">
                                            <p className="current-day date-active">WE</p>
                                            <p className="current-date date-active">14</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">TH</p>
                                            <p className="current-date">15</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">FR</p>
                                            <p className="current-date">16</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">SA</p>
                                            <p className="current-date">17</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">SU</p>
                                            <p className="current-date">18</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">MO</p>
                                            <p className="current-date">19</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">TU</p>
                                            <p className="current-date">20</p>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                                <div className='bottom-line' style={{position: "relative", top:"-19px"}}></div>
                            </Col>
                            <Col xs={12}>
                                <Form className='search-box'>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="text" placeholder="Search | Example : Attaindence" />
                                        <div className='search-icon'><img src={SearchIcon} alt="search" /></div>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
  )
}
export default Header;