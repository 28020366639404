import React, {useState, forwardRef } from 'react'
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import Student from '../../assets/image/student.png';
import CalenderBlue from '../../assets/image/svg/Icon_Calender_Blue.svg';
import BottomNav from '../../Components/BottomNav/BottomNav';
import Header from '../../Components/Header/Header';
import { Formik, Form , Field } from 'formik';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import DropFileInput from '../../Components/DropFileInput/DropFileInput';

const HomeWork = () => {

const onFileChange = (files) => {
    console.log(files);
}

const [startDate, setStartDate] = useState(new Date());
const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
<p className="example-custom-input" onClick={onClick} ref={ref}>
<span><img src={CalenderBlue} alt="calender" /></span>  {value}
</p>
));

const [startDate2, setStartDate2] = useState(new Date());
const ExampleCustomInput2 = forwardRef(({ value, onClick }, ref) => (
<p className="example-custom-input" onClick={onClick} ref={ref}>
<span><img src={CalenderBlue} alt="calender" /></span>  {value}
</p>
));

  return (
    <>
        <Container fluid className='m-0 p-0 overflow-x-hidden'>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col>
                            <Header/>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                        <Formik
                            initialValues={{ email: '', password: '', files: [], }}
                            onSubmit={(values, { setSubmitting }) => {
                                setTimeout(() => {
                                alert(JSON.stringify(values, null, 2));
                                setSubmitting(false);
                                }, 400);
                            }}
                            valnameationSchema={yup.object().shape({
                                recaptcha: yup.array(),
                              })}
                            >
                            {({ values, handleSubmit, setFieldValue
                                /* and other goodies */
                            }) => (
                            <Form onSubmit={handleSubmit}>
                            <div className='services-section'>
                                <Row>
                                    <Col xs={12}>
                                        <h3 className='title-inner'>Home Work</h3>
                                        <p className='sub-title-inner'>14-06-2022 | Wednesday</p>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='attendance-bottom-line'></div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='attendance-dropdown'>
                                            <Field as="select" name="class" className="mb-3">
                                                <option value="1">6th Grade A Section</option>
                                                <option value="3">6th Grade B Section</option>
                                                <option value="2">6th Grade c Section</option>
                                            </Field>
                                            <Field as="select" name="subject" className="mb-3">
                                                <option value="maths">Maths</option>
                                                <option value="biology">Biology</option>
                                                <option value="english">English</option>
                                            </Field>
                                            <Field as="textarea" name="description" className="form-control mb-3">Enter Description</Field>
                                            <DropFileInput
                                                onFileChange={(files) => onFileChange(files)}
                                            />
                                            <Row className='mt-3'>
                                                <Col xs={6}>
                                                    <p className="calender-label">Start Date</p>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={(date) => setStartDate(date)}
                                                        customInput={<ExampleCustomInput />}
                                                        dateFormat="MMMM d, yyyy"
                                                    />
                                                </Col>
                                                <Col xs={6}>
                                                    <p className="calender-label">End Date</p>
                                                    <DatePicker
                                                        selected={startDate2}
                                                        onChange={(date) => setStartDate2(date)}
                                                        customInput={<ExampleCustomInput2 />}
                                                        dateFormat="MMMM d, yyyy"
                                                    />
                                                </Col>
                                            </Row>

                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='attendance-bottom-line'></div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className='attendance-list-view'>
                                            <Table className='mb-3 '>
                                                <tbody>
                                                    <tr>
                                                        <td className='title p-l-50'>Name</td>
                                                        <td className='title-underline'>Select Student</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='title'><img src={Student} alt="name"/> Rahul Kumar</td>
                                                        <td className='text-center'>
                                                            <Field
                                                                type="radio"
                                                                name="present"
                                                                className='form-check-input'
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='title'><img src={Student} alt="name"/> Rahul Kumar</td>
                                                        <td className='text-center'>
                                                            <Field
                                                                type="radio"
                                                                name="present"
                                                                className='form-check-input'
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='title'><img src={Student} alt="name"/> Rahul Kumar</td>
                                                        <td className='text-center'>
                                                            <Field
                                                                type="radio"
                                                                name="present"
                                                                className='form-check-input'
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='title'><img src={Student} alt="name"/> Rahul Kumar</td>
                                                        <td className='text-center'>
                                                            <Field
                                                                type="radio"
                                                                name="present"
                                                                className='form-check-input'
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='title'><img src={Student} alt="name"/> Rahul Kumar</td>
                                                        <td className='text-center'>
                                                            <Field
                                                                type="radio"
                                                                name="present"
                                                                className='form-check-input'
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='title'><img src={Student} alt="name"/> Rahul Kumar</td>
                                                        <td className='text-center'>
                                                            <Field
                                                                type="radio"
                                                                name="present"
                                                                className='form-check-input'
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                       <div className='text-center mb-5 pb-2 d-flex justify-content-around' >
                                            <Button variant="outline-primary" className='attendance-btn'>Reset</Button>
                                            <Button variant="primary" type="submit" className=' ml-3 attendance-btn'>Submit</Button>
                                       </div>
                                    </Col>
                                </Row>
                            </div>
                            </Form>
                             )}
                             </Formik>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BottomNav/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>
  )
}
export default HomeWork;