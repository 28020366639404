import React from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink } from 'react-router-dom';
import Logo from "./../../assets/image/svg/zunior_logo.svg";

const NavMenu = () => {
  return (
    <>
      {[false].map((expand) => (
        <Navbar key={expand} expand={expand} className="mb-3">
          {/* <Container fluid> */}
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                <img src={Logo} alt="screen" width="100%"/>  
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1">
                  <Nav.Link><NavLink exact activeClassName="active" to="/home">Home</NavLink></Nav.Link>
                  <Nav.Link><NavLink activeClassName="active" to="/attendance">Attaindence</NavLink></Nav.Link>
                  <Nav.Link><NavLink activeClassName="active" to="/time-table">Time Table</NavLink></Nav.Link>
                  <Nav.Link><NavLink activeClassName="active" to="/homework">Home Work</NavLink></Nav.Link>
                  <Nav.Link><NavLink activeClassName="active" to="/event">Event</NavLink></Nav.Link>
                  <Nav.Link><NavLink activeClassName="active" to="/profile">Profile</NavLink></Nav.Link>
                  {/* <NavDropdown
                    title="Dropdown"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                    <NavDropdown.Item href="#action4">
                      Another action
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item href="#action5">
                      Something else here
                    </NavDropdown.Item>
                  </NavDropdown> */}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          {/* </Container> */}
        </Navbar>
      ))}
    </>

  )
}

export default NavMenu