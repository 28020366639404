import React from 'react'
import { Container, Row, Col, Form, Tab, Tabs } from 'react-bootstrap';
import {Link, useNavigate } from "react-router-dom";
import NotificationIcon from '../../assets/image/svg/Notification_Icon_blue.svg';
import IconOther from '../../assets/image/svg/Icon_Other.svg';
import SearchIcon from '../../assets/image/svg/search-icon.svg';
import BackIcon from '../../assets/image/svg/back-arrow.svg';
import CallIcon from '../../assets/image/svg/Icon _Call.svg';
import ChatIcon from '../../assets/image/svg/Message_Icon.svg';
import PlusIcon from '../../assets/image/svg/Shape_01.svg';
import User from '../../assets/image/user1.png'
import BottomNav from '../../Components/BottomNav/BottomNav';
import NavMenu from '../../Components/NavMenu/NavMenu';

export default function Message() {
    
    const navigate = useNavigate();

  return (
    <>
        <Container fluid className='m-0 p-0 overflow-x-hidden'>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col>
                        <div className='home-intro-section'>
                        <Row>
                            <Col xs={8}>
                                <h3 className='user-name'><span className='mr-3' onClick={() => navigate(-1)}><img src={BackIcon} alt="back"/></span> Messages</h3>
                            </Col>
                            <Col xs={4}>
                                <div className='d-flex justify-content-end'>
                                    <div className='notification-wrapper'>
                                        <img src={NotificationIcon} alt="notification-icon" className='notification-icon' />
                                        <div className='notification-status'></div>
                                    </div>
                                    <div className='other-icon-wrapper'>
                                        <NavMenu/>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <Form className='search-box mt-3'>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="text" placeholder="Search | Example : Attaindence" />
                                        <div className='search-icon'><img src={SearchIcon} alt="search" /></div>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                        
                    </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='services-section'>
                                <Row>
                                    <Col xs={12}>
                                        <Tabs
                                            defaultActiveKey="individual"
                                            id="justify-tab-example"
                                            className="mb-3"
                                            justify
                                            >
                                            <Tab eventKey="individual" title="Individual">
                                                <Row>
                                                    <Col xs={12}>
                                                        <div className='message-content-wrapper'>
                                                            <div className='d-flex justify-content-start'>
                                                                <div className='user-image'>
                                                                    <img src={User} alt="user-image"/>
                                                                </div>
                                                                <div className='user-detail'>
                                                                    <h3>Arlene</h3>
                                                                    <p>F: Jane Copper | 6th B</p>
                                                                </div>
                                                                <div className='message-icon'>
                                                                    <img src={CallIcon} alt="arrow-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='message-content-wrapper'>
                                                            <div className='d-flex justify-content-start'>
                                                                <div className='user-image'>
                                                                    <img src={User} alt="user-image"/>
                                                                </div>
                                                                <div className='user-detail'>
                                                                    <h3>Arlene</h3>
                                                                    <p>F: Jane Copper | 6th B</p>
                                                                </div>
                                                                <div className='message-icon'>
                                                                    <img src={CallIcon} alt="arrow-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='message-content-wrapper'>
                                                            <div className='d-flex justify-content-start'>
                                                                <div className='user-image'>
                                                                    <img src={User} alt="user-image"/>
                                                                </div>
                                                                <div className='user-detail'>
                                                                    <h3>Arlene</h3>
                                                                    <p>F: Jane Copper | 6th B</p>
                                                                </div>
                                                                <div className='message-icon'>
                                                                    <img src={CallIcon} alt="arrow-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='message-content-wrapper'>
                                                            <div className='d-flex justify-content-start'>
                                                                <div className='user-image'>
                                                                    <img src={User} alt="user-image"/>
                                                                </div>
                                                                <div className='user-detail'>
                                                                    <h3>Arlene</h3>
                                                                    <p>F: Jane Copper | 6th B</p>
                                                                </div>
                                                                <div className='message-icon'>
                                                                    <img src={CallIcon} alt="arrow-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='message-content-wrapper'>
                                                            <div className='d-flex justify-content-start'>
                                                                <div className='user-image'>
                                                                    <img src={User} alt="user-image"/>
                                                                </div>
                                                                <div className='user-detail'>
                                                                    <h3>Arlene</h3>
                                                                    <p>F: Jane Copper | 6th B</p>
                                                                </div>
                                                                <div className='message-icon'>
                                                                    <img src={CallIcon} alt="arrow-icon" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className='chat-icon'>
                                                            <Link to="/chat"><img src={ChatIcon} alt="chat-icon"></img></Link>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Tab>
                                            <Tab eventKey="groups" title="Groups">
                                            <Row>
                                                    <Col xs={12}>
                                                        <div className='message-content-wrapper'>
                                                            <div className='d-flex justify-content-start'>
                                                                <div className='user-image'>
                                                                    <img src={User} alt="user-image"/>
                                                                </div>
                                                                <div className='user-detail'>
                                                                    <h3>Arlene</h3>
                                                                    <p>F: Jane Copper | 6th B</p>
                                                                </div>
                                                                {/* <div className='message-icon'>
                                                                    <img src={CallIcon} alt="arrow-icon" />
                                                                    <img src={PlusIcon} alt="group-add" className='add-chat'></img>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='message-content-wrapper'>
                                                            <div className='d-flex justify-content-start'>
                                                                <div className='user-image'>
                                                                    <img src={User} alt="user-image"/>
                                                                </div>
                                                                <div className='user-detail'>
                                                                    <h3>Arlene</h3>
                                                                    <p>F: Jane Copper | 6th B</p>
                                                                </div>
                                                                {/* <div className='message-icon'>
                                                                    <img src={CallIcon} alt="arrow-icon" />
                                                                    <img src={PlusIcon} alt="group-add" className='add-chat'></img>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='message-content-wrapper'>
                                                            <div className='d-flex justify-content-start'>
                                                                <div className='user-image'>
                                                                    <img src={User} alt="user-image"/>
                                                                </div>
                                                                <div className='user-detail'>
                                                                    <h3>Arlene</h3>
                                                                    <p>F: Jane Copper | 6th B</p>
                                                                </div>
                                                                {/* <div className='message-icon'>
                                                                    <img src={CallIcon} alt="arrow-icon" />
                                                                    <img src={PlusIcon} alt="group-add" className='add-chat'></img>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Tab>
                                        </Tabs>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BottomNav/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>
  )
}
