import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import NotificationIcon from '../../assets/image/svg/Notification_Icon_blue.svg';
// import SearchIcon from '../../assets/image/svg/search-icon.svg';
import BackIcon from '../../assets/image/svg/back-arrow.svg';
// import CallIcon from '../../assets/image/svg/Icon _Call.svg';
// import ChatIcon from '../../assets/image/svg/Message_Icon.svg';
// import PlusIcon from '../../assets/image/svg/Shape_01.svg';
// import User from '../../assets/image/user1.png'
import BottomNav from '../../Components/BottomNav/BottomNav';
// import { CircularProgressbar } from 'react-circular-progressbar';
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import NavMenu from '../../Components/NavMenu/NavMenu';

export default function Attendance() {
    
    const navigate = useNavigate();

    const value = 20;

  return (
    <>
        <Container fluid className='m-0 p-0 overflow-x-hidden'>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col>
                            <div className='home-intro-section pb-5'>
                                <Row>
                                    <Col xs={8}>
                                        <h3 className='user-name'><span className='mr-3' onClick={() => navigate(-1)}><img src={BackIcon} alt="back"/></span> Attendance</h3>
                                    </Col>
                                    <Col xs={4}>
                                        <div className='d-flex justify-content-end'>
                                            <div className='notification-wrapper'>
                                                <img src={NotificationIcon} alt="notification-icon" className='notification-icon' />
                                                <div className='notification-status'></div>
                                            </div>
                                            <div className='other-icon-wrapper'>
                                                <NavMenu/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='services-section mt-5' style={{top: "-75px", paddingTop: "30px"}}>
                                <Row>
                                    <Col xs={12}>
                                        <h3 className='mb-3 attendance-title'>June 2022</h3>
                                        <div className='attendance-list'>
                                            <Row>
                                                <Col xs={5}>
                                                    <div style={{width: "100px", height:"100px"}}>
                                                        <CircularProgressbarWithChildren value={value} maxValue={30}>
                                                            {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                                            <p className='mb-0' style={{fontSize: "18px"}}><strong>{`${value}`}</strong></p>
                                                            <p className='mb-0' style={{fontSize: "12px"}}>Days</p>
                                                            <p className='mb-0' style={{fontSize: "12px"}}>Present</p>
                                                        </CircularProgressbarWithChildren>
                                                    </div>
                                                </Col>
                                                <Col xs={7}>
                                                    <h3 className='mb-0'>Total Working Days</h3>
                                                    <p>26 Days</p>
                                                    <h3 className='mb-0'>Official Leave</h3>
                                                    <p className='mb-0'>4 Days</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div classNamemt-3>
                                            <div className='attendance-bottom-line'></div>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <h3 className='mb-3 attendance-title'>July 2022</h3>
                                        <div className='attendance-list'>
                                            <Row>
                                                <Col xs={5}>
                                                    <div style={{width: "100px", height:"100px"}}>
                                                        <CircularProgressbarWithChildren value={value} maxValue={30}>
                                                            {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                                                            <p className='mb-0' style={{fontSize: "18px"}}><strong>{`${value}`}</strong></p>
                                                            <p className='mb-0' style={{fontSize: "12px"}}>Days</p>
                                                            <p className='mb-0' style={{fontSize: "12px"}}>Present</p>
                                                        </CircularProgressbarWithChildren>
                                                    </div>
                                                </Col>
                                                <Col xs={7}>
                                                    <h3 className='mb-0'>Total Working Days</h3>
                                                    <p>26 Days</p>
                                                    <h3 className='mb-0'>Official Leave</h3>
                                                    <p className='mb-0'>4 Days</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div classNamemt-3>
                                            <div className='attendance-bottom-line'></div>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <h3 className='mb-3 attendance-title'>August 2022</h3>
                                        <div className='attendance-list'>
                                            <Row>
                                                <Col xs={5}>
                                                    <div style={{width: "100px", height:"100px"}}>
                                                        <CircularProgressbarWithChildren value={value} maxValue={30}>
                                                            <p className='mb-0' style={{fontSize: "18px"}}><strong>{`${value}`}</strong></p>
                                                            <p className='mb-0' style={{fontSize: "12px"}}>Days</p>
                                                            <p className='mb-0' style={{fontSize: "12px"}}>Present</p>
                                                        </CircularProgressbarWithChildren>
                                                    </div>
                                                </Col>
                                                <Col xs={7}>
                                                    <h3 className='mb-0'>Total Working Days</h3>
                                                    <p>26 Days</p>
                                                    <h3 className='mb-0'>Official Leave</h3>
                                                    <p className='mb-0'>4 Days</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div classNamemt-3>
                                            <div className='attendance-bottom-line'></div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BottomNav/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>
  )
}
