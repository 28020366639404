import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import {useNavigate } from "react-router-dom";
import BackIcon from '../../assets/image/svg/back-arrow.svg';
import cameraIcon from '../../assets/image/svg/Camera_Icon.svg';
import SendIcon from '../../assets/image/svg/Send_Icon.svg';
import User from '../../assets/image/user1.png'
import { Formik } from 'formik';

export default function Chat() {
    
    const navigate = useNavigate();

  return (
    <>
        <Container fluid className='m-0 p-0 overflow-x-hidden'>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col>
                        <div className='home-intro-section pb-5'>
                            <Row>
                                <Col xs={12}>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <h3 className='user-name mb-0'><span className='mr-3' onClick={() => navigate(-1)}><img src={BackIcon} alt="back"/></span> Arlene</h3>
                                            <p className='user-detail ml-5'>F: Jane Cooper | 6th B Section</p>
                                        </div>
                                        <div className='user-avtar mt-4'>
                                            <img src={User} alt="user"/>
                                        </div>
                                        
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='services-section' style={{height:'520px'}}>
                                <Row>
                                    <Col>
                                        <div className='chat-day'>
                                            <p>Yesterday</p>
                                        </div>
                                        <div className='chat-message-left'>
                                                <p>Lorem Ipsum is simply dummy text</p>
                                        </div>
                                        <div className='chat-date-time'>
                                                <p>Yesterday, 11:58</p>
                                            </div>
                                        <div className='chat-message-right'>
                                                <p>Lorem Ipsum is simply dummy text</p>
                                        </div>
                                        <div className='chat-date-time'>
                                                <p className='text-right'>Yesterday, 11:58</p>
                                            </div>
                                        <div className='chat-message-left'>
                                                <p>Lorem Ipsum is simply dummy text</p>
                                        </div>
                                        <div className='chat-date-time'>
                                                <p>Yesterday, 11:58</p>
                                            </div>
                                        <div className='chat-message-right'>
                                                <p>Lorem Ipsum is simply dummy text</p>
                                        </div>
                                        <div className='chat-date-time'>
                                                <p className='text-right'>Yesterday, 11:58</p>
                                            </div>
                                        <div className='chat-message-left'>
                                                <p>Lorem Ipsum is simply dummy text</p>
                                        </div>
                                        <div className='chat-date-time'>
                                            <p>Yesterday, 11:58</p>
                                        </div>
                                        <div className='chat-message-right'>
                                            <p>Lorem Ipsum is simply dummy text</p>
                                        </div>
                                        <div className='chat-date-time'>
                                            <p className='text-right'>Yesterday, 11:58</p>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <div className='chat-input-box'>
                                            <Formik
                                                initialValues={{ name: '' }}
                                                onSubmit={(values, actions) => {
                                                    setTimeout(() => {
                                                    alert(JSON.stringify(values, null, 2));
                                                    actions.setSubmitting(false);
                                                    }, 1000);
                                                }}
                                                >
                                                {props => (
                                                    <form onSubmit={props.handleSubmit}>
                                                    <input
                                                        type="text"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.name}
                                                        name="name"
                                                        placeholder='Type Somethng ...'
                                                        className='form-control'
                                                        autoFocus
                                                    />
                                                    {props.errors.name && <div id="feedback">{props.errors.name}</div>}
                                                    <button type="submit" className='btn btn-send'><img src={SendIcon}  alt="send" /></button>
                                                    
                                                    </form>
                                                )}
                                            </Formik>
                                            <img src={cameraIcon} alt="file upload" className='upload-attacchment'></img>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    
                </Col>
            </Row>
        </Container>
    </>
  )
}
