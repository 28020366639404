import React, {useState, forwardRef } from 'react';
import { Container, Row, Col, Form, Tab, Tabs } from 'react-bootstrap';
import {Link} from 'react-router-dom'
import NotificationIcon from '../../assets/image/svg/Notification_Icon_blue.svg';
import IconOther from '../../assets/image/svg/Icon_Other.svg';
import WarningIcon from '../../assets/image/svg/Icon_Warning.svg';
import CalenderIcon from '../../assets/image/svg/Icon_Calender_01.svg';
import SearchIcon from '../../assets/image/svg/search-icon.svg';
import MessageIcon from '../../assets/image/svg/services/Icon Message.svg'
import HistoryIcon from '../../assets/image/svg/services/History_Icon.svg'
import CalenderIconBlue from '../../assets/image/svg/services/Icon_Calender.svg'
import EditIcon from '../../assets/image/svg/services/Icon_Edit.svg'
import PeopleIcon from '../../assets/image/svg/services/Icon_People.svg'
import UserIcon from '../../assets/image/svg/services/Icon_User.svg'
import MoreIcon from '../../assets/image/svg/services/More_Icon.svg'
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination } from "swiper";
import BottomNav from '../../Components/BottomNav/BottomNav';
import Event from '../Event/Event';
import NavMenu from '../../Components/NavMenu/NavMenu';
import DatePicker from 'react-datepicker';

export default function Home() {

    const [startDate, setStartDate] = useState(new Date());
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <p className="text-white text-end" onClick={onClick} ref={ref}>
    <span><img src={CalenderIcon} alt="calender" /></span>  {value}
    </p>
    ));

  return (
    <>
        <Container fluid className='m-0 p-0 overflow-x-hidden'>
            <Row>
                <Col lg={12}>
                    <div className='home-intro-section'>
                        <Row>
                            <Col xs={8}>
                                <h3 className='user-name'>Jacob Jones</h3>
                                <p className='user-detail'>F: Jane Cooper | 6th B Section</p>
                            </Col>
                            <Col xs={4}>
                                <div className='d-flex justify-content-end'>
                                    <div className='notification-wrapper'>
                                        <img src={NotificationIcon} alt="notification-icon" className='notification-icon' />
                                        <div className='notification-status'></div>
                                    </div>
                                    <div className='other-icon-wrapper'>
                                        {/* <img src={IconOther} alt="notification-icon" className='other-icon' /> */}
                                        <NavMenu/>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className='bottom-line'></div>
                            </Col>
                            <Col xs={12}>
                                <div className='d-flex justify-content-between mt-3'>
                                    <h4 className='select-date'>Select Date <span className='ml-1'><img src={WarningIcon} alt=""/></span></h4>
                                    {/* <h4 className='calender-title'><span className='mr-1'><img src={CalenderIcon} alt=""/></span> June 2022</h4> */}
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        customInput={<ExampleCustomInput />}
                                        dateFormat="MMMM yyyy"
                                    />
                                </div>
                            </Col>
                            <Col xs={12} className='mt-2'>
                                <Swiper
                                    slidesPerView={7}
                                    spaceBetween={10}
                                    pagination={{
                                    clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">MO</p>
                                            <p className="current-date">12</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">TU</p>
                                            <p className="current-date">13</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list active-list">
                                            <p className="current-day date-active">WE</p>
                                            <p className="current-date date-active">14</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">TH</p>
                                            <p className="current-date">15</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">FR</p>
                                            <p className="current-date">16</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">SA</p>
                                            <p className="current-date">17</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">SU</p>
                                            <p className="current-date">18</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">MO</p>
                                            <p className="current-date">19</p>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="d-block day-list">
                                            <p className="current-day">TU</p>
                                            <p className="current-date">20</p>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                                <div className='bottom-line' style={{position: "relative", top:"-19px"}}></div>
                            </Col>
                            <Col xs={12}>
                                <Form className='search-box'>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="text" placeholder="Search | Example : Attaindence" />
                                        <div className='search-icon'><img src={SearchIcon} alt="search" /></div>
                                    </Form.Group>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                    <Row>
                        <Col xs={12}>
                            <div className='services-section'>
                                <Tabs defaultActiveKey="reports" id="justify-tab-example" className="mb-3" justify>
                                    <Tab eventKey="reports" title="Reports">
                                        <Row className='mt-2'>
                                            <Col xs={4} className='mb-2'>
                                                <div className='text-center'>
                                                    <Link to="/attendance">
                                                        <p className='mb-0 service-icon'><img src={CalenderIconBlue} alt="Calender" /></p>
                                                        <p className='service-title'>Attendance</p>
                                                    </Link>
                                                </div>
                                            </Col>
                                            <Col xs={4} className='mb-2'>
                                                <div className='text-center'>
                                                    <Link to="/homework">
                                                        <p className='mb-0 service-icon'><img src={EditIcon} alt="Calender" /></p>
                                                        <p className='service-title'>Home Works</p>
                                                    </Link>
                                                </div>
                                            </Col>
                                            <Col xs={4} className='mb-2'>
                                                <div className='text-center'>
                                                    <Link to="/behaviour">
                                                        <p className='mb-0 service-icon'><img src={UserIcon} alt="Calender" /></p>
                                                        <p className='service-title'>Behaviour</p>
                                                    </Link>
                                                </div>
                                            </Col>
                                            <Col xs={4} className='mb-2'>
                                                <div className='text-center'>
                                                    <p className='mb-0 service-icon'><img src={PeopleIcon} alt="Calender" /></p>
                                                    <p className='service-title'>Activity</p>
                                                </div>
                                            </Col>
                                            <Col xs={4} className='mb-2'>
                                                <div className='text-center'>
                                                    <p className='mb-0 service-icon'><img src={HistoryIcon} alt="Calender" /></p>
                                                    <p className='service-title'>Circulars</p>
                                                </div>
                                            </Col>
                                            <Col xs={4} className='mb-2'>
                                                <div className='text-center'>
                                                    <Link to="/message">
                                                        <p className='mb-0 service-icon'><img src={MessageIcon} alt="Calender" /></p>
                                                        <p className='service-title'>Messages</p>
                                                    </Link>
                                                </div>
                                            </Col>
                                            <Col xs={4} className='mb-2'>
                                                <div className='text-center'>
                                                    <p className='mb-0 service-icon'><img src={MoreIcon} alt="Calender" /></p>
                                                    <p className='service-title'>More</p>
                                                </div>
                                            </Col>
                                            {/* <div className='service-bottom-line'></div> */}
                                        </Row>
                                        {/* <Row className='pb-5'>
                                            <Col xs={12}>
                                                <div className='d-flex justify-content-between'>
                                                    <h3 className='title'>Events on June,2022</h3>
                                                    <p className='view-all-text'><Link to="/event">View All</Link></p>
                                                </div>
                                            </Col>
                                            <Col xs={12}>
                                                <div className='event-content-wrapper'>
                                                    <div className='d-flex justify-content-start'>
                                                        <div className='event-image'>
                                                            <img src={PersonalImage} alt="event-image"/>
                                                        </div>
                                                        <div className='event-descripion'>
                                                            <h3 className='title'>Personal Training</h3>
                                                            <p className='sub-title'>6th Class B Section | 12:00 PM</p>
                                                        </div>
                                                        <div className='event-right-arrow'>
                                                            <img src={ArrowIcon} alt="arrow-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='event-content-wrapper'>
                                                    <div className='d-flex justify-content-start'>
                                                        <div className='event-image'>
                                                            <img src={YogaImage} alt="event-image"/>
                                                        </div>
                                                        <div className='event-descripion'>
                                                            <h3 className='title'>Yoga</h3>
                                                            <p className='sub-title'>12th Class C Section | 10:00 PM</p>
                                                        </div>
                                                        <div className='event-right-arrow'>
                                                            <img src={ArrowIcon} alt="arrow-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='event-content-wrapper'>
                                                    <div className='d-flex justify-content-start'>
                                                        <div className='event-image'>
                                                            <img src={StretchImage} alt="event-image"/>
                                                        </div>
                                                        <div className='event-descripion'>
                                                            <h3 className='title'>Stretch</h3>
                                                            <p className='sub-title'>12th Class A Section | 02:00 PM</p>
                                                        </div>
                                                        <div className='event-right-arrow'>
                                                            <img src={ArrowIcon} alt="arrow-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='event-content-wrapper'>
                                                    <div className='d-flex justify-content-start'>
                                                        <div className='event-image'>
                                                            <img src={BoxingImage} alt="event-image"/>
                                                        </div>
                                                        <div className='event-descripion'>
                                                            <h3 className='title'>Boxing</h3>
                                                            <p className='sub-title'>12th Class B Section | 01:00 PM</p>
                                                        </div>
                                                        <div className='event-right-arrow'>
                                                            <img src={ArrowIcon} alt="arrow-icon" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row> */}
                                    </Tab>
                                    <Tab eventKey="events" title="Events">
                                       <Event/>
                                    </Tab>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BottomNav/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>
  )
}
