import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import PersonalImage from '../../assets/image/personal.png'
import YogaImage from '../../assets/image/yoga.png'
import StretchImage from '../../assets/image/stretch.png'
import BoxingImage from '../../assets/image/boxing.png'
import BottomNav from '../../Components/BottomNav/BottomNav';

const Event = () => {
  return (
    <>
        <Container fluid className='overflow-x-hidden'>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col xs={12} className='p-x-15'>
                            <div className='d-flex justify-content-between'>
                                <h3 className='title'>Events on June 14,2022</h3>
                                {/* <p className='view-all-text'>View All</p> */}
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className='event-content-wrapper'>
                                <div className='d-flex justify-content-start'>
                                    <div className='event-image'>
                                        <img src={PersonalImage} alt="event-image"/>
                                    </div>
                                    <div className='event-descripion'>
                                        <h3 className='title'>Personal Training</h3>
                                        <p className='sub-title'>6th Class B Section | 12:00 PM</p>
                                    </div>
                                    {/* <div className='event-right-arrow'>
                                        <img src={ArrowIcon} alt="arrow-icon" />
                                    </div> */}
                                </div>
                            </div>
                            <div className='event-content-wrapper'>
                                <div className='d-flex justify-content-start'>
                                    <div className='event-image'>
                                        <img src={YogaImage} alt="event-image"/>
                                    </div>
                                    <div className='event-descripion'>
                                        <h3 className='title'>Yoga</h3>
                                        <p className='sub-title'>12th Class C Section | 10:00 PM</p>
                                    </div>
                                    {/* <div className='event-right-arrow'>
                                        <img src={ArrowIcon} alt="arrow-icon" />
                                    </div> */}
                                </div>
                            </div>
                            <div className='event-content-wrapper'>
                                <div className='d-flex justify-content-start'>
                                    <div className='event-image'>
                                        <img src={StretchImage} alt="event-image"/>
                                    </div>
                                    <div className='event-descripion'>
                                        <h3 className='title'>Stretch</h3>
                                        <p className='sub-title'>12th Class A Section | 02:00 PM</p>
                                    </div>
                                    {/* <div className='event-right-arrow'>
                                        <img src={ArrowIcon} alt="arrow-icon" />
                                    </div> */}
                                </div>
                            </div>
                            <div className='event-content-wrapper'>
                                <div className='d-flex justify-content-start'>
                                    <div className='event-image'>
                                        <img src={BoxingImage} alt="event-image"/>
                                    </div>
                                    <div className='event-descripion'>
                                        <h3 className='title'>Boxing</h3>
                                        <p className='sub-title'>12th Class B Section | 01:00 PM</p>
                                    </div>
                                    {/* <div className='event-right-arrow'>
                                        <img src={ArrowIcon} alt="arrow-icon" />
                                    </div> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='service-bottom-line'></div>
                    <Row className='pb-5'>
                        <Col xs={12} className='pb-3'>
                            <div className='d-flex justify-content-between'>
                                <h3 className='title'>Events on June 15,2022</h3>
                                {/* <p className='view-all-text'>View All</p> */}
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className='event-content-wrapper'>
                                <div className='d-flex justify-content-start'>
                                    <div className='event-image'>
                                        <img src={PersonalImage} alt="event-image"/>
                                    </div>
                                    <div className='event-descripion'>
                                        <h3 className='title'>Personal Training</h3>
                                        <p className='sub-title'>6th Class B Section | 12:00 PM</p>
                                    </div>
                                    {/* <div className='event-right-arrow'>
                                        <img src={ArrowIcon} alt="arrow-icon" />
                                    </div> */}
                                </div>
                            </div>
                            <div className='event-content-wrapper'>
                                <div className='d-flex justify-content-start'>
                                    <div className='event-image'>
                                        <img src={YogaImage} alt="event-image"/>
                                    </div>
                                    <div className='event-descripion'>
                                        <h3 className='title'>Yoga</h3>
                                        <p className='sub-title'>12th Class C Section | 10:00 PM</p>
                                    </div>
                                    {/* <div className='event-right-arrow'>
                                        <img src={ArrowIcon} alt="arrow-icon" />
                                    </div> */}
                                </div>
                            </div>
                            <div className='event-content-wrapper'>
                                <div className='d-flex justify-content-start'>
                                    <div className='event-image'>
                                        <img src={StretchImage} alt="event-image"/>
                                    </div>
                                    <div className='event-descripion'>
                                        <h3 className='title'>Stretch</h3>
                                        <p className='sub-title'>12th Class A Section | 02:00 PM</p>
                                    </div>
                                    {/* <div className='event-right-arrow'>
                                        <img src={ArrowIcon} alt="arrow-icon" />
                                    </div> */}
                                </div>
                            </div>
                            <div className='event-content-wrapper'>
                                <div className='d-flex justify-content-start'>
                                    <div className='event-image'>
                                        <img src={BoxingImage} alt="event-image"/>
                                    </div>
                                    <div className='event-descripion'>
                                        <h3 className='title'>Boxing</h3>
                                        <p className='sub-title'>12th Class B Section | 01:00 PM</p>
                                    </div>
                                    {/* <div className='event-right-arrow'>
                                        <img src={ArrowIcon} alt="arrow-icon" />
                                    </div> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BottomNav/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>
  )
}
export default Event;