import React from 'react'
import { Container, Row, Col, Card, Accordion, Table} from 'react-bootstrap';
import {Link, useNavigate } from "react-router-dom";
import NotificationIcon from '../../assets/image/svg/Notification_Icon_blue.svg';
import IconOther from '../../assets/image/svg/Icon_Other.svg';
import BackIcon from '../../assets/image/svg/back-arrow.svg';
import BottomNav from '../../Components/BottomNav/BottomNav';
import YogaImage from '../../assets/image/yoga.png'
import NavMenu from '../../Components/NavMenu/NavMenu';

const TimeTable = () => {
    
    const navigate = useNavigate();

    const value = 20;

  return (
    <>
        <Container fluid className='m-0 p-0 overflow-x-hidden'>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col>
                            <div className='home-intro-section p-b-100'>
                                <Row>
                                    <Col xs={8}>
                                        <h3 className='user-name'><span className='mr-3' onClick={() => navigate(-1)}><img src={BackIcon} alt="back"/></span> Time Table</h3>
                                    </Col>
                                    <Col xs={4}>
                                        <div className='d-flex justify-content-end'>
                                            <div className='notification-wrapper'>
                                                <img src={NotificationIcon} alt="notification-icon" className='notification-icon' />
                                                <div className='notification-status'></div>
                                            </div>
                                            <div className='other-icon-wrapper'>
                                                <NavMenu/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='services-section mt-5' style={{top: "-100px", paddingTop: "30px"}}>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Monday</Accordion.Header>
                                        <Accordion.Body>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Period</th>
                                                        <th>Subject</th>
                                                        <th>Teacher</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>I</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>II</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>III</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IV</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>V</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VI</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Tuesday</Accordion.Header>
                                        <Accordion.Body>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Period</th>
                                                        <th>Subject</th>
                                                        <th>Teacher</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>I</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>II</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>III</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IV</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>V</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VI</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Wednesday</Accordion.Header>
                                        <Accordion.Body>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Period</th>
                                                        <th>Subject</th>
                                                        <th>Teacher</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>I</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>II</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>III</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IV</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>V</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VI</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Thrusday</Accordion.Header>
                                        <Accordion.Body>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Period</th>
                                                        <th>Subject</th>
                                                        <th>Teacher</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>I</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>II</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>III</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IV</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>V</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VI</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>Friday</Accordion.Header>
                                        <Accordion.Body>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Period</th>
                                                        <th>Subject</th>
                                                        <th>Teacher</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>I</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>II</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>III</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IV</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>V</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VI</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>Saturday</Accordion.Header>
                                        <Accordion.Body>
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Period</th>
                                                        <th>Subject</th>
                                                        <th>Teacher</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>I</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>II</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>III</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>IV</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>V</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                    <tr>
                                                        <td>VI</td>
                                                        <td>Maths</td>
                                                        <td>Prachi Pandey</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BottomNav/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default TimeTable;
