import React from 'react'
import { Container, Row, Col, Card, Tab, Tabs} from 'react-bootstrap';
import {Link, useNavigate } from "react-router-dom";
import NotificationIcon from '../../assets/image/svg/Notification_Icon_blue.svg';
import BackIcon from '../../assets/image/svg/back-arrow.svg';
import BottomNav from '../../Components/BottomNav/BottomNav';
import YogaImage from '../../assets/image/yoga.png'
import NavMenu from '../../Components/NavMenu/NavMenu';

const Profile = () => {
    
    const navigate = useNavigate();

    const value = 20;

  return (
    <>
        <Container fluid className='m-0 p-0 overflow-x-hidden'>
            <Row>
                <Col lg={12}>
                    <Row>
                        <Col>
                            <div className='home-intro-section pb-5'>
                                <Row>
                                    <Col xs={8}>
                                        <h3 className='user-name'><span className='mr-3' onClick={() => navigate(-1)}><img src={BackIcon} alt="back"/></span> Profile</h3>
                                    </Col>
                                    <Col xs={4}>
                                        <div className='d-flex justify-content-end'>
                                            <div className='notification-wrapper'>
                                                <img src={NotificationIcon} alt="notification-icon" className='notification-icon' />
                                                <div className='notification-status'></div>
                                            </div>
                                            <div className='other-icon-wrapper'>
                                                <NavMenu/>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className='profile'>
                                            <img src={YogaImage} alt="profile-image"/>
                                            <h3>Rohit Kumar</h3>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div className='services-section mt-5' style={{top: "-100px", paddingTop: "30px"}}>
                                <Tabs defaultActiveKey="student" id="justify-tab-example" className="mb-3" justify>
                                    <Tab eventKey="student" title="Student">
                                        <div className='profile-content-wrapper'>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500'>Roll No:</p>
                                                <p className='sub-title'>01</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500'>Class:</p>
                                                <p className='sub-title'>6th B Section</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500'>Shift:</p>
                                                <p className='sub-title'>Shift 1</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500'>Gender:</p>
                                                <p className='sub-title'>Male</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500'>Blood Group:</p>
                                                <p className='sub-title'>B+</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500 mb-2'>Address:</p>
                                                <p className='sub-title mb-2'>Saguna More, Bailey Road,<br></br> Patna 801503</p>
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="parents" title="Parents">
                                        <div className='profile-content-wrapper'>
                                            <h5 className='title text-primary fw-500'>Father's Detail:</h5>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500'>Father Name:</p>
                                                <p className='sub-title'>John Smith</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500'>Father Occupation:</p>
                                                <p className='sub-title'>Business</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500'>Mobile:</p>
                                                <p className='sub-title'>+91 987 654 3210</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500 mb-2'>Email:</p>
                                                <p className='sub-title mb-2'>test@gmail.com</p>
                                            </div>
                                        </div>
                                        <div className='profile-content-wrapper'>
                                            <h5 className='title text-primary fw-500'>Mother's Detail:</h5>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500'>Mother Name:</p>
                                                <p className='sub-title'>Amelia Ava</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500'>Mother Occupation:</p>
                                                <p className='sub-title'>Business</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500'>Mobile:</p>
                                                <p className='sub-title'>+91 987 654 3210</p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p className='fw-500 mb-2'>Email:</p>
                                                <p className='sub-title mb-2'>test@gmail.com</p>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BottomNav/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>
  )
}

export default Profile;
