import React, {useState} from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import Home from "./../../assets/image/svg/Group.svg";
import Message from "./../../assets/image/svg/Icon Message.svg";
import Notification from "./../../assets/image/svg/Notification_Icon.svg";
import User from "./../../assets/image/svg/Icon_User.svg";

export default function BottomNav() {

const [showText, setShowText] = useState(false);
const onClick = () => setShowText(!showText);

const [showText2, setShowText2] = useState(false);
const onClick2 = () => setShowText2(!showText2);

const [showText3, setShowText3] = useState(false);
const onClick3 = () => setShowText3(!showText3);

const [showText4, setShowText4] = useState(false);
const onClick4 = () => setShowText4(!showText4);

  return (
    <>
        <Container>
            <Row>
                <Col>
                    <div className='bottom-nav'>
                        <div onClick={onClick}>
                            {showText ? <p className='nav-button'> Home </p> : <img src={Home} alt="home" className='nav-icon' />}
                        </div>
                        <div onClick={onClick2}>
                            {showText2 ? <p className='nav-button'>Message</p> : <img src={Message} alt="message" className='nav-icon' />}
                        </div>
                        <div onClick={onClick3}>
                            {showText3 ? <p className='nav-button'>Notification</p> : <img src={Notification} alt="notification" className='nav-icon' />}
                        </div>
                        <div onClick={onClick4}>
                            {showText4 ? <p className='nav-button'>Setting</p> : <img src={User} alt="user" className='nav-icon' />}
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </>
  )
}
